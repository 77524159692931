import React from 'react';

import Layout from '../components/Layout/layout';
import SEO from '../components/SEO/seo';

import CountDownTESt from '../components/CountDown';

const CountDownPage = () => (
  <Layout>
    <SEO title="Count Down" />
    <CountDownTESt />
  </Layout>
);

export default CountDownPage;
