import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import { LanguageContext } from "../../lang";
import { navigate } from "gatsby";

const CountDown = ({ seconds, subscriptionData }) => {
	const {
		translations: { rideWaiting },
	} = useContext(LanguageContext);
	const { text } = rideWaiting;
	const [count, setCount] = useState(seconds);
	const [rideData, setRideData] = useState(null);
	console.log("seconds------", seconds);
	useEffect(() => {
		let countDownTimer = setTimeout(() => {
			setCount((count) => count - 1);
		}, 1000);
		if (subscriptionData !== null) {
			clearTimeout(countDownTimer);
			setRideData(subscriptionData);
		}
		if (count === 0 || count < 0) {
			clearTimeout(countDownTimer);
		}
		return () => {
			clearTimeout(countDownTimer);
		};
	});
	if (rideData !== null) {
		const { status } = rideData;
		if (status === "missed") {
			navigate("/ride-not-found");
		} else {
			navigate("/payment", {
				state: {
					data: { rideData },
				},
			});
		}
	}
	return (
		<section id="count-down-section">
			<div className="container">
				<div className="row">
					<div className="col-12 text-center">
						<p className="count-down-text">‘’{text}’’</p>
					</div>
				</div>

				<div className="row mt-4 d-flex justify-content-center">
					<div className="col-6 text-center seconds-col">
						<div className="seconds-wrap p-4">
							<h3>{count}</h3>
							<h4>Seconds</h4>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CountDown;
